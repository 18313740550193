var render = function render(){var _vm=this,_c=_vm._self._c;return (!!_vm.post)?_c('div',[_c('b-card',{staticClass:"post-card",attrs:{"type":"post"},on:{"click":function($event){return _vm.openPost()}}},[_c('div',{staticClass:"author"},[_c('a',[_c('h4',{staticClass:"title"},[_c('a',{staticClass:"author-name"},[_vm._v(" "+_vm._s(_vm.users[_vm.post.userId].username)+" ")]),_c('a',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.post.createdAt)+" ")])])])]),_c('p',{staticClass:"card-text"}),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"tab-header mb-1"},[_c('h1',{staticStyle:{"color":"black","margin-bottom":"-10px"}},[_vm._v(_vm._s(_vm.post.title))]),_c('div',{staticClass:"line"},[_vm._v("_______")]),_c('h6',[_vm._v(_vm._s(_vm.post.subtitle))])]),(_vm.type == 'lg')?_c('div',{staticClass:"card-desc-wrap"},[_c('p',{staticClass:"card-description",domProps:{"innerHTML":_vm._s(_vm.post.text)}})]):_vm._e()]),(_vm.manage)?_c('div',{staticClass:"post-actions"},[_c('b-btn',{staticStyle:{"margin-right":"5px"},attrs:{"variant":"success","size":"sm","disabled":!_vm.hasUpdatePermission,"icon":""},on:{"click":() => {
            _vm.editPostModal = true;
          }}},[_c('b-icon',{attrs:{"icon":"gear"}})],1),_c('b-btn',{attrs:{"variant":"danger","size":"sm","disabled":!_vm.hasDeletePermission,"icon":""},on:{"click":() => {
            _vm.deletePostModal = true;
          }}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1):_vm._e()]),_c('b-modal',{model:{value:(_vm.deletePostModal),callback:function ($$v) {_vm.deletePostModal=$$v},expression:"deletePostModal"}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Delete "+_vm._s(_vm.post.title))])]),_c('div',[_vm._v(" Are you sure you want to delete Post "),_c('b',[_vm._v(_vm._s(_vm.post.title))]),_vm._v("? ")]),_c('template',{slot:"footer"},[_c('b-btn',{attrs:{"type":"secondary"},on:{"click":function($event){_vm.deletePostModal = false}}},[_vm._v("Cancel")]),_c('b-btn',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.removePost()}}},[_vm._v("Delete")])],1)],2),_c('b-modal',{model:{value:(_vm.editPostModal),callback:function ($$v) {_vm.editPostModal=$$v},expression:"editPostModal"}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Update "+_vm._s(_vm.post.title))])]),_c('div',[_c('edit-posts-form',{attrs:{"isNew":false,"model":_vm.post},on:{"update":() => {
            _vm.editPostModal = false;
            _vm.getPost();
          }}})],1)],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }